import React from 'react'
import Hontakt from '../components/hontakt/Hontakt'
import Footer from '../componenty/footer/Footer'
import Navbar from '../components/navbar/Navbar'

const KontaktPage = () => {
    return (
        <>  <Navbar/>
            <Hontakt/>
            <Footer/>
        </>
    )
}

export default KontaktPage
