import React from 'react'

import Navbar from '../components/navbar/Navbar'
import Recovery from '../components/recovery/Recovery'

const RecoveryPage = () => {
    return (
        <>
           <Navbar /> 
           <Recovery />
        </>
    )
}

export default RecoveryPage
